<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12" sm="12" md="11" lg="11">
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Customer</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.customerName"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Number <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="sequenceNumber"
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Type PO <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-select
                  outlined
                  flat
                  dense
                  background-color="white"
                  :items="listTypePO"
                  v-model="typePO"
                  :rules="[v => !!v || 'Type PO is required']"
                ></v-select>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PO<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  append-icon="mdi-magnify"
                  return-object
                  v-model="purchaseOrder"
                  :items="salePurchaseOrders"
                  :rules="[v => !!v || 'PO is required']"
                  :filter="filterObject"
                >
                  <template v-slot:selection="data">
                    {{ data.item.purchaseOrderNumber }}
                  </template>
                  <template v-slot:item="data">
                    <v-list-item v-on="data.on">
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.purchaseOrderNumber }}</v-list-item-title>
                        <v-list-item-subtitle>{{ data.item.account.name }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="6" sm="6" md="3" lg="3" class="py-0" v-if="form.type === 'PO'">
                <v-btn color="success" @click="addPurchaseOrder" class="mr-2">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn color="primary" @click="viewTablePo">
                  <v-icon>
                    mdi-file-table-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Site <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  item-text="name"
                  item-value="id"
                  :items="listSite"
                  v-model="form.siteId"
                  @change="onChangeSite"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Invoice Date <span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-menu
                  v-model="menuInvoiceDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="form.invoiceDate"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.invoiceDate"
                    :allowed-dates="getAllowedDates()"
                    @input="menuInvoiceDate = false"
                    @change="onInputInvoiceDuration"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Term</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.invoiceDuration"
                  suffix="days"
                  @change="onInputInvoiceDuration"
                  :rules="[v => v >= 0 || 'Term must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader> Bill To<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  disabled
                  v-model="form.billTo"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Bill To Contact</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-autocomplete
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.billToContact"
                  :items="contacts"
                  item-text="name"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Percentage Invoice<span class="red--text">*</span></v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentageInvoice"
                  suffix="%"
                  @change="onInputPercentageInvoice"
                  :rules="percentageInvoiceRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>PPN</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model.number="form.percentagePpn"
                  suffix="%"
                  @change="onInputPercentagePpn"
                  :rules="[v => v >= 0 || 'PPN must more than equals 0']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Rate</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-text-field
                  type="number"
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.rate"
                  prefix="Rp."
                  :rules="[v => v >= 1 || 'Rate must more than equals 1']"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0">
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-checkbox v-model="form.fp" label="Faktur Pajak Digunggung"></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-checkbox
              v-model="form.backDate"
              label="Back Date Invoice"
              @change="onChangeBackDate"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="form.percentagePpn > 0 && !form.fp">
          <v-col cols="12" sm="12" md="9" lg="9">
            <v-row>
              <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                <v-subheader>Tax Invoice Serial Number</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <!-- <v-text-field
                  outlined
                  flat
                  dense
                  background-color="white"
                  v-model="form.taxInvoiceSerialNumber"
                ></v-text-field> -->
                <v-row>
                  <v-col cols="3">
                    <v-select
                      dense
                      outlined
                      flat
                      background-color="white"
                      :items="arrayFronts"
                      v-model="twoDigit"
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      dense
                      outlined
                      flat
                      background-color="white"
                      :items="arrayFrontNexts"
                      v-model="oneDigit"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" v-if="!form.backDate">
                    <v-text-field
                      outlined
                      flat
                      dense
                      background-color="white"
                      v-model="serialNumber.number"
                      disabled
                    ></v-text-field>
                    <v-col cols="6" v-if="form.backDate">
                      <v-autocomplete
                        outlined
                        flat
                        dense
                        background-color="white"
                        append-icon="mdi-magnify"
                        item-text="serialNumber"
                        return-object
                        v-model="tax"
                        :items="taxNumbers"
                        :rules="[v => !!v || 'Tax Number is required']"
                        @change="onChangeTax"
                      ></v-autocomplete>
                    </v-col>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Remark</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <v-textarea
                  outlined
                  flat
                  rows="3"
                  background-color="white"
                  v-model="form.remark"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6">
            <v-row>
              <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
                <v-subheader>Discount</v-subheader>
              </v-col>
              <v-col cols="8" sm="8" md="6" lg="6" class="py-0">
                <span v-if="form.discount < 100 && form.discount >= 0">{{ form.discount }}%</span>
                <span v-if="form.discount > 100">{{ formatPrice(form.discount) }}</span>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tabs vertical>
              <v-tab background-color="light-blue accent-3" dark class="px-0">
                <v-icon>
                  mdi-note-text
                </v-icon>
              </v-tab>
              <v-tab>
                <v-icon>
                  mdi-information
                </v-icon>
              </v-tab>
              <v-tab-item>
                <v-card flat class="px-2">
                  <v-row>
                    <v-col cols="6"></v-col>
                    <v-col cols="6" align-self="start" class="text-right pb-0">
                      <p class="headline font-italic">
                        Item Detail <span class="red--text">*</span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12">
                      <v-data-table
                        :headers="headers"
                        :items="items"
                        disable-pagination
                        hide-default-footer
                        @click:row="onClickRow"
                      >
                        <template v-slot:item.no="{ item }">
                          {{ items.map(x => x).indexOf(item) + 1 }}
                        </template>
                        <template v-slot:item.unitPrice="{ item }">
                          {{ formatPrice(item.unitPrice) }}
                        </template>
                        <template v-slot:item.total="{ item }">
                          {{ formatPrice(item.total) }}
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-title class="font-italic">Additional Info</v-card-title>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Due Date</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.invoiceDueDate }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="pb-0">
                      <v-subheader>Currency</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="pb-0">
                      <span>{{ form.currency }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Percent Up To This Invoice</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.percentageUpTo }}%</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Site</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.site }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>Sales</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ form.salesName }}</span>
                    </v-col>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>PO Number</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ purchaseOrder ? purchaseOrder.purchaseOrderNumber : "" }}</span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" sm="4" md="2" lg="2" class="py-0">
                      <v-subheader>DP Percentage</v-subheader>
                    </v-col>
                    <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
                      <span>{{ totalPercentage }}%</span>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Sub Total</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{
                  formatPrice(form.type === "DO" ? totalPoAmount : form.totalAmount)
                }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total Invoice</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterInvoice) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">PPH</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.pphAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2" v-if="form.percentagePpn > 0">
            <v-card outlined>
              <v-card-title class="py-0">Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.taxAmount) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="2">
            <v-card outlined>
              <v-card-title class="py-0">Total After Tax</v-card-title>
              <v-card-text class="text-right mt-3">
                <span>{{ formatPrice(form.totalAmountAfterTax) }}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
        <v-row class="py-0">
          <v-col cols="12" class="mb-4 py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="checkJournal">
                  <v-icon large>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </template>
              <span>Save</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-sale-product
      :item="item"
      :index="index"
      :dialog="dialog"
      :accounts="accounts"
      :percentage="form.percentageInvoice"
      @save="saveItem"
      @close="close"
      @deleteItem="deleteItem"
    ></dialog-sale-product>
    <dialog-view-table
      :dialog="dialogTablePo"
      :purchaseOrders="form.purchaseOrders"
      @close="close"
      @deleteItem="deleteItemTablePo"
      type="PO"
    ></dialog-view-table>
    <dialog-review-journal
      :dialog="dialogReview"
      :journals="journals"
      @save="submit"
      @close="close"
      currency="IDR"
    ></dialog-review-journal>
  </v-form>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import DialogSaleProduct from "@/components/DialogSaleProduct";
import DialogViewTable from "@/components/DialogViewTable";
import DialogReviewJournal from "@/components/DialogReviewJournal";
import { fiscalMonthMixin } from "@/services/mixins/fiscalMonthMixin";

export default {
  name: "create-sale-invoice",
  mixins: [fiscalMonthMixin],
  components: {
    "dialog-sale-product": DialogSaleProduct,
    "dialog-view-table": DialogViewTable,
    DialogReviewJournal,
  },
  data: () => ({
    defaultForm: {
      formNumber: "",
      invoiceNumber: "",
      invoiceDate: moment().format("yyyy-MM-DD"),
      invoiceDueDate: moment().format("yyyy-MM-DD"),
      invoiceDuration: 0,
      customerId: null,
      siteId: 0,
      customerName: null,
      customerAddress: null,
      type: "PO",
      salesName: "",
      percentageInvoice: 0,
      percentageDownPayment: 0,
      percentagePaid: 0,
      percentagePpn: 11,
      currency: "IDR",
      site: "",
      percentageUpTo: 0,
      discount: 0,
      billTo: "",
      billToContact: "",
      taxInvoiceSerialNumber: "",
      materialDocumentNumber: "",
      totalAmount: 0,
      rate: 1,
      taxAmount: 0,
      pphAmount: 0,
      totalAmountAfterInvoice: 0,
      totalAmountAfterTax: 0,
      totalDownPayment: 0,
      products: [],
      fp: false,
      taxId: null,
      status: "Outstanding",
      deliverySales: [],
      purchaseOrders: [],
      downPaymentPercentageDtos: [],
    },
    menuInvoiceDate: false,
    form: {},
    items: [],
    itemsTmp: [],
    customer: null,
    purchaseOrder: null,
    salePurchaseOrders: [],
    accounts: [],
    journals: [],
    listSite: [],
    dialog: false,
    dialogTablePo: false,
    dialogReview: false,
    item: {},
    index: -1,
    twoDigit: "01",
    oneDigit: 0,
    arrayFronts: ["01", "02", "03", "04", "05", "06", "07", "08", "09"],
    arrayFrontNexts: [0, 1],
    listTypePO: ["mix", "service only"],
    typePO: "mix",
    valid: true,
    tax: {},
    accountNumber: "",
    headers: [
      {
        text: "No",
        value: "no",
        sortable: false,
        divider: true,
      },
      {
        text: "Product Detail",
        value: "partDesc",
        sortable: false,
        divider: true,
      },
      {
        text: "Qty",
        value: "quantity",
        sortable: false,
        divider: true,
      },
      {
        text: "U/M",
        value: "unitMeasure",
        sortable: false,
        divider: true,
      },
      {
        text: "U/Price",
        value: "unitPrice",
        sortable: false,
        divider: true,
      },
      {
        text: "Disc %",
        value: "discount",
        sortable: false,
        divider: true,
      },
      {
        text: "Amount",
        value: "total",
        sortable: false,
        divider: true,
      },
      {
        text: "Line Item",
        value: "lineItem",
        sortable: false,
        divider: true,
      },
      {
        text: "Part#",
        value: "partNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "PO#",
        value: "poNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "DO#",
        value: "doNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Mat Doc#",
        value: "materialDocumentNumber",
        sortable: false,
        divider: true,
      },
      {
        text: "Account",
        value: "accountName",
        sortable: false,
        divider: true,
      },
    ],
  }),

  computed: {
    totalAmount() {
      let subtotal = 0;
      this.items.map(key => {
        subtotal = subtotal + key.total;
      });
      if (this.form.discount > 0 && this.form.discount <= 100) {
        return parseFloat((subtotal - subtotal * (this.form.discount / 100)).toFixed(0));
      } else {
        return parseFloat((subtotal - this.form.discount).toFixed(0));
      }
    },
    totalPoAmount() {
      let subtotal = 0;
      if (this.form.purchaseOrders.length > 0 && this.form.purchaseOrders) {
        this.form.purchaseOrders[0].products.map(key => {
          subtotal = subtotal + key.total;
        });
      }
      return subtotal - subtotal * (this.form.discount / 100);
    },
    pphAmount() {
      let subtotal = 0;
      this.items.map(key => {
        subtotal = subtotal + key.pph23Amount;
      });
      return parseFloat(subtotal.toFixed(0));
    },
    ...mapState("external", ["customers", "contacts"]),
    ...mapState("saleInvoice", ["lastFormNumber", "sequenceNumber", "lastPercentage"]),
    ...mapState("saleDownPayment", ["totalPercentageDownPayment"]),
    ...mapState("taxNumber", ["serialNumber", "taxNumbers"]),
    percentageInvoiceRules() {
      if (this.purchaseOrder) {
        const total = this.totalPercentage + this.purchaseOrder.percentageInvoice;
        return [
          v => !!v || "Percentage invoice is required",
          v =>
            (v <= 100 - total && v >= 1) ||
            `Percentage invoice must be more than 1 and less than ${100 - total}`,
        ];
      }
      const total = this.totalPercentage;
      return [
        v => !!v || "Percentage invoice is required",
        v =>
          (v <= 100 - total && v >= 1) ||
          `Percentage invoice must be more than 1 and less than ${100 - total}`,
      ];
    },
    totalPercentage() {
      let total = 0;
      this.totalPercentageDownPayment.forEach(x => {
        total += x.percentage;
      });
      return total;
    },
  },

  watch: {
    totalAmount(val) {
      this.form.totalAmount = val;
      //
      this.onInputPercentageInvoice();
    },
    pphAmount(val) {
      this.form.pphAmount = val;
    },
  },

  methods: {
    checkJournal() {
      this.onInputPercentageInvoice();
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.journals = [
          {
            accountNumber: this.accountNumber,
            accountName: `${this.form.customerName} IDR`,
            debit: this.form.totalAmountAfterTax * this.form.rate,
            credit: 0,
          },
        ];
        this.totalPercentageDownPayment.forEach(x => {
          this.journals.unshift({
            accountNumber: "331.00000",
            accountName: `UANG MUKA PENJUALAN IDR`,
            debit: this.totalAmount * (x.percentage / 100) * this.form.rate,
            credit: 0,
          });
        });
        let map = this.items.map(x => {
          return {
            accountNumber: x.accountNumber,
            accountName: x.accountName,
          };
        });
        let array = map.filter(
          (v, i) =>
            map.findIndex(
              x => x.accountNumber === v.accountNumber && x.accountName === v.accountName
            ) === i
        );
        array.forEach(y => {
          let total = 0;
          this.items.forEach(x => {
            if (x.accountName === y.accountName) {
              total += x.total * (this.form.percentageInvoice / 100);
            }
          });
          this.journals.push({
            accountNumber: y.accountNumber,
            accountName: `${y.accountName}`,
            debit: 0,
            credit: this.calculateAccount(total, array.length) * this.form.rate,
          });
        });
        if (this.form.taxAmount > 0) {
          this.journals.push({
            accountNumber: "320.00001",
            accountName: `HUTANG PPN KELUARAN`,
            debit: 0,
            credit: this.form.taxAmount * this.form.rate,
          });
        }
        // if (this.form.pphAmount > 0) {
        //   this.journals.unshift({
        //     accountName: `PAJAK PPH - 23`,
        //     debit: this.form.pphAmount,
        //     credit: 0,
        //   });
        // }
        this.journals.forEach(x => {
          this.$store
            .dispatch("chartOfAccount/getPointer", { accountNumber: x.accountNumber })
            .then(response => (x.pointers = response.data));
        });
        this.dialogReview = true;
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    submit() {
      if (this.$refs.form.validate() && this.items.length > 0) {
        this.form.invoiceNumber = this.sequenceNumber;
        this.form.formNumber = this.lastFormNumber;
        if (this.form.backDate) {
          this.form.taxInvoiceSerialNumber = this.form.fp
            ? ""
            : this.twoDigit + this.oneDigit + "." + this.tax.serialNumber;
          this.form.taxId = this.tax.id;
        } else {
          this.form.taxInvoiceSerialNumber = this.form.fp
            ? ""
            : this.twoDigit + this.oneDigit + "." + this.serialNumber.number;
          this.form.taxId = this.serialNumber.id;
        }
        this.form.products = this.items;
        this.form.percentageDownPayment = this.totalPercentage;
        this.form.totalDownPayment = (this.totalPercentage / 100) * this.totalAmount;
        this.form.downPaymentPercentageDtos = this.totalPercentageDownPayment;

        // let totalDebit =
        //   this.form.totalDownPayment + this.form.totalAmountAfterTax + this.form.pphAmount;
        let totalDebit = this.form.totalDownPayment + this.form.totalAmountAfterTax;
        let totalCredit =
          this.form.taxAmount +
          this.totalAmount *
            ((this.form.percentageDownPayment + this.form.percentageInvoice) / 100);

        if (totalDebit - totalCredit === 0) {
          this.$store.commit("SET_LOADER", true);
          this.$store
            .dispatch("saleInvoice/create", this.form)
            .then(() => {
              this.clear();
              this.$store.commit("SET_LOADER", false);
            })
            .catch(() => {
              this.$store.dispatch("toast", {
                type: "error",
                message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
              });
              this.$store.commit("SET_LOADER", false);
            });
        } else {
          let different = totalDebit - totalCredit;
          if (different > 0) {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Debit`,
            });
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: `Journal not balance with -${Math.abs(different)} on Credit`,
            });
          }
        }
      } else {
        this.$store.dispatch("toast", {
          type: "error",
          message: "Items is required",
        });
      }
    },
    onChangeSite(val) {
      if (val === 0) {
        this.items = this.itemsTmp;
      } else {
        this.items = this.itemsTmp.filter(x => x.siteId === this.form.siteId);
      }
    },
    async getSite(id) {
      await this.$store.dispatch("external/getSite", id).then(response => {
        response.data.unshift({ id: 0, name: "All" });
        this.listSite = response.data;
      });
    },
    async getPurchaseOrders() {
      await this.$store.dispatch("external/getSalePurchaseOrderAll", false).then(response => {
        let array = response.data.filter(x => {
          let array = x.products.map(x => x.type);
          if (array.includes("Service")) {
            return x;
          }
        });
        this.salePurchaseOrders = array;
      });
    },
    async getAdditionalDataFromCustomerId(id) {
      this.$store.commit("SET_LOADER", true);
      Promise.all([this.getSite(id), await this.$store.dispatch("external/getContact", id)])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    invoiceDueDate() {
      let dueDate = moment(this.form.invoiceDate)
        .add(this.form.invoiceDuration, "days")
        .format("yyyy-MM-DD");
      return dueDate;
    },
    onInputInvoiceDuration(val) {
      if (this.form.currency !== "IDR") {
        this.getCurrency({ code: this.form.currency, date: val });
      } else {
        this.form.rate = 1;
      }
      this.form.invoiceDueDate = this.invoiceDueDate();
    },
    resetInput() {
      this.purchaseOrder = null;
      this.form.purchaseOrders = [];
      this.items = [];
      this.form.site = "";
      this.form.customerAddress = "";
      this.form.salesName = "";
      this.form.currency = "";
      this.form.billToContact = null;
      this.form.totalAmountAfterInvoice = 0;
      this.form.taxAmount = 0;
      this.form.totalAmountAfterTax = 0;
      this.form.percentagePaid = 0;
      this.form.percentageUpTo = 0;
    },
    async getAR(id) {
      await this.$store
        .dispatch("chartOfAccount/getAR", id)
        .then(response => (this.accountNumber = response.data));
    },
    async addPurchaseOrder() {
      if (this.purchaseOrder == null) {
        this.$store.dispatch("toast", {
          type: "error",
          message: "PO number belum dipilih",
        });
      } else {
        if (this.form.purchaseOrders.length > 0) {
          if (
            this.form.purchaseOrders[0].purchaseOrderNumber ===
            this.purchaseOrder.purchaseOrderNumber
          ) {
            if (this.form.purchaseOrders.indexOf(this.purchaseOrder) !== -1) {
              this.$store.dispatch("toast", {
                type: "error",
                message: "PO sudah dipakai pada form",
              });
            } else {
              this.form.purchaseOrders.push(this.purchaseOrder);
              this.itemsTmp = this.itemsTmp.concat(
                this.purchaseOrder.products.filter(x => {
                  if (this.typePO === "mix") {
                    return x;
                  } else {
                    if (x.accountNumber === "651.00000") {
                      return x;
                    }
                  }
                })
              );
              this.items = this.items.concat(
                this.purchaseOrder.products.filter(x => {
                  if (this.typePO === "mix") {
                    return x;
                  } else {
                    if (x.accountNumber === "651.00000") {
                      return x;
                    }
                  }
                })
              );
              this.onInputPercentageInvoice();
              this.form.site = this.form.purchaseOrders.map(x => x.site).join(",");
              this.form.discount = this.purchaseOrder.discount;
            }
          } else {
            this.$store.dispatch("toast", {
              type: "error",
              message: "PO number tidak sama",
            });
          }
        } else {
          this.form.purchaseOrders.push(this.purchaseOrder);
          this.itemsTmp = this.itemsTmp.concat(
            this.purchaseOrder.products.filter(x => {
              if (this.typePO === "mix") {
                return x;
              } else {
                if (x.accountNumber === "651.00000") {
                  return x;
                }
              }
            })
          );
          this.items = this.items.concat(
            this.purchaseOrder.products.filter(x => {
              if (this.typePO === "mix") {
                return x;
              } else {
                if (x.accountNumber === "651.00000") {
                  return x;
                }
              }
            })
          );
          this.form.salesName = this.purchaseOrder.user.name;
          this.form.customerAddress = this.purchaseOrder.address.street;
          this.form.currency = this.purchaseOrder.currency;
          if (this.purchaseOrder.currency !== "IDR") {
            this.getCurrency({ code: this.purchaseOrder.currency, date: this.form.invoiceDate });
          } else {
            this.form.rate = 1;
          }
          this.form.billToContact = this.purchaseOrder.contact.id;
          this.form.site = this.form.purchaseOrders.map(x => x.site).join(",");
          this.form.discount = this.purchaseOrder.discount;
          this.form.customerId = this.purchaseOrder.account.id;
          this.form.customerName = this.purchaseOrder.account.name;
          this.form.billTo = this.purchaseOrder.account.name;
          this.getAdditionalDataFromCustomerId(this.purchaseOrder.account.id);
          this.getAR(this.purchaseOrder.account.id);
        }
        this.$store.commit("SET_LOADER", true);
        Promise.all([
          this.$store.dispatch("saleDownPayment/totalDownPayment", this.purchaseOrder.id),
          this.$store.dispatch("saleInvoice/getPercentagePaid", this.purchaseOrder.id),
        ])
          .then(() => {
            this.form.percentageInvoice =
              100 - this.totalPercentage - this.purchaseOrder.percentageInvoice;
            this.onInputPercentageInvoice();
            this.$store.commit("SET_LOADER", false);
          })
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    viewTablePo() {
      this.dialogTablePo = true;
    },
    deleteItemTablePo(item) {
      const index = this.form.purchaseOrders.map(x => x).indexOf(item);
      if (index > -1) {
        this.form.purchaseOrders.splice(index, 1);
        item.products.forEach(x => {
          const index = this.itemsTmp.indexOf(x);
          const index2 = this.items.indexOf(x);
          if (index >= 0) this.itemsTmp.splice(index, 1);
          if (index2 >= 0) this.items.splice(index, 1);
        });
        this.form.site = this.form.purchaseOrders.map(x => x.site).join(",");
      }
    },
    onInputPercentageInvoice() {
      this.form.totalAmountAfterInvoice = parseFloat(
        (this.totalAmount * (this.form.percentageInvoice / 100)).toFixed(0)
      );
      this.form.taxAmount = Math.floor(
        parseFloat((this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100)).toFixed(2))
      );
      this.items.forEach(x => {
        let total = (x.total * this.form.percentageInvoice) / 100;
        x.pph23Amount = (total * x.pph23) / 100;
      });
      this.itemsTmp.forEach(x => {
        let total = (x.total * this.form.percentageInvoice) / 100;
        x.pph23Amount = (total * x.pph23) / 100;
      });
      this.form.pphAmount = this.pphAmount;
      // this.form.totalAmountAfterTax =
      //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
      this.form.percentagePaid = (this.form.totalAmountAfterInvoice / this.totalPoAmount) * 100;
      this.form.percentageUpTo =
        this.lastPercentage +
        this.totalPercentage +
        this.form.percentagePaid +
        this.purchaseOrder.percentageInvoice;
    },
    onInputPercentagePpn() {
      this.form.taxAmount = Math.floor(
        parseFloat((this.form.totalAmountAfterInvoice * (this.form.percentagePpn / 100)).toFixed(2))
      );
      // this.form.totalAmountAfterTax =
      //   this.form.totalAmountAfterInvoice + this.form.taxAmount - this.form.pphAmount;
      this.form.totalAmountAfterTax = this.form.totalAmountAfterInvoice + this.form.taxAmount;
    },
    onClickRow(item) {
      this.item = item;
      this.index = this.items.map(x => x).indexOf(item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.dialogTablePo = false;
      this.dialogReview = false;
    },
    saveItem(data) {
      this.items[data.index] = data.item;
      this.close();
    },
    deleteItem(index) {
      if (index > -1) {
        this.items.splice(index, 1);
        this.dialog = false;
      }
    },
    clear() {
      this.customer = null;
      this.purchaseOrder = null;
      this.items = [];
      this.itemsTmp = [];
      this.form = Object.assign({}, this.defaultForm);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    onChangeTax(val) {
      this.form.taxId = val.id;
    },
    onChangeBackDate(val) {
      if (val) {
        this.tax = {};
      } else {
        this.form.taxId = this.serialNumber.id;
      }
    },
    async getAccounts() {
      const response = await this.$store.dispatch("chartOfAccount/getAccounts");
      if (response.status === 200) {
        this.accounts = response.data;
      }
    },
    async getCurrency(params) {
      this.$store
        .dispatch("currency/getRate", params)
        .then(response => (this.form.rate = response.data));
    },
    async getInitialData() {
      this.$store.commit("SET_LOADER", true);
      Promise.all([
        await this.$store.dispatch("taxNumber/getNumber"),
        await this.$store.dispatch("saleInvoice/getSequenceNumber"),
        await this.$store.dispatch("saleInvoice/getLastFormNumber"),
        await this.$store.dispatch("taxNumber/getAll", false),
        this.getAccounts(),
        this.getPurchaseOrders(),
      ])
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    // eslint-disable-next-line no-unused-vars
    filterObjectDo(item, queryText, itemText) {
      return (
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    filterObject(item, queryText) {
      return (
        item.purchaseOrderNumber.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.account.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
      );
    },
    calculateAccount(total, divide) {
      if (this.form.discount > 0 && this.form.discount <= 100) {
        return total - total * (this.form.discount / 100);
      }
      return total + ((this.totalPercentage / 100) * this.totalAmount) / divide;
    },
  },

  mounted() {
    this.getInitialData();
    this.form = Object.assign({}, this.defaultForm);
  },
  beforeMount() {
    this.form.percentagePpn = JSON.parse(localStorage.getItem("setting_tax") || []).find(
      x => x.name === "PPN"
    ).percentage;
  },
};
</script>

<style></style>
